<div
  class="fxflex fxstartcenter full-height links-container"
  data-cy="links-container"
>
  @for (route of routes; track route.name) {
    <a
      [attr.data-cy]="'header-' + route.name"
      class="header-link clickable"
      (click)="navigate.emit(route)"
      >{{ 'Layout.' + route.name | translate }}</a
    >
  }
</div>
