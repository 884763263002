import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { has } from 'lodash';
import {
  KeycloakRole,
  Membership,
  Organization,
  OrganizationsService,
  UserService,
  organizationsActions,
  routerActions,
  userActions,
} from 'rio-core';
import { TrackingService } from 'rio-tracking';
import { Observable, forkJoin, from, of, switchMap, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

export function rioUserInitializer(
  keycloakService: KeycloakService,
  store: Store,
  organizationService: OrganizationsService,
  userService: UserService,
  trackingService: TrackingService,
  httpClient: HttpClient,
): () => Observable<unknown> {
  return () =>
    from(
      keycloakService.init({
        config: 'assets/config.json',
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: false,
        },
        bearerPrefix: 'Bearer',
        enableBearerInterceptor: true,
        loadUserProfileAtStartUp: true,
        bearerExcludedUrls: ['/assets', '/clients/public'],
      }),
    ).pipe(
      catchError((error: unknown) => of(error)),
      switchMap(() => keycloakService.loadUserProfile()),
      switchMap((user: KeycloakProfile) =>
        forkJoin([
          of(user),
          organizationService.getOrganizations(),
          userService.getMemberships(user.id),
          httpClient.get('assets/config.json'),
        ]),
      ),
      tap(
        ([user, organizations, memberships, config]: [
          KeycloakProfile,
          Organization[],
          Membership[],
          { mixpanelKey: string },
        ]) => {
          const keycloakRole = getKeycloakRole(keycloakService);
          const firstValidMembership = memberships.find(membership =>
            Boolean(membership.organizationId),
          );

          setTracking(
            trackingService,
            config,
            user,
            firstValidMembership,
            organizations,
          );
          setUser(store, user);
          setKeycloakRole(store, keycloakRole);
          setMemberships(store, memberships);
          setOrganizations(store, organizations, firstValidMembership);

          navigate(store, keycloakService, firstValidMembership, keycloakRole);
        },
      ),
    );
}

function getKeycloakRole(keycloakService: KeycloakService): KeycloakRole {
  return keycloakService
    .getUserRoles()
    .filter(
      role => role === 'support' || role === 'super_admin',
    )[0] as KeycloakRole;
}

function setTracking(
  trackingService: TrackingService,
  config: { mixpanelKey: string },
  user: KeycloakProfile,
  firstValidMembership: Membership,
  organizations: Organization[],
) {
  trackingService.init(config.mixpanelKey);
  trackingService.identify(user.id);

  const organizationId = firstValidMembership
    ? firstValidMembership.organizationId
    : organizations[0]?.id;

  const isInternal = organizations.find(org => org.id === organizationId)
    ?.isInternal;

  trackingService.setOrganization(organizationId, isInternal);
}

function setUser(store: Store, user: KeycloakProfile) {
  store.dispatch(
    userActions.loadUser({
      user: {
        id: user.id,
        email: user.email,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
      },
    }),
  );
}

function setKeycloakRole(store: Store, keycloakRole: KeycloakRole) {
  if (keycloakRole) {
    store.dispatch(userActions.setKeycloakRole({ keycloakRole }));
  }
}

function setMemberships(store: Store, memberships: Membership[]) {
  store.dispatch(userActions.setMemberships({ memberships }));
}

function setOrganizations(
  store: Store,
  organizations: Organization[],
  firstValidMembership: Membership,
) {
  store.dispatch(
    organizationsActions.loadOrganizationsSuccess({ organizations }),
  );

  if (!firstValidMembership && organizations.length > 0) {
    store.dispatch(
      userActions.selectOrganization({
        organizationId: organizations[0].id,
      }),
    );
  }
}

function navigate(
  store: Store,
  keycloakService: KeycloakService,
  firstValidMembership: Membership,
  keycloakRole: 'super_admin' | 'support',
) {
  if (!firstValidMembership && !keycloakRole) {
    store.dispatch(routerActions.navigateToNoOrganizationPage());
  } else {
    // acr indicates if the user logs into application
    // 0 === refresh of active session
    // 1 === login into application
    const hasUserLoggedIn =
      has(keycloakService, '_instance.idTokenParsed.acr') &&
      keycloakService['_instance']['idTokenParsed']['acr'] === '1';
    if (hasUserLoggedIn) {
      store.dispatch(routerActions.initialNavigationToDefaultRoute());
    }
  }
}
