<app-dialog-wrapper>
  <ng-container title>
    {{ 'AddOrganization.Title' | translate }}
  </ng-container>

  <ng-container content>
    <form [formGroup]="form">
      <span
        class="form-label"
        data-cy="organization-name-label"
        [class.warn]="form.controls.name.invalid && form.controls.name.touched"
      >
        {{ 'AddOrganization.Description' | translate }}
      </span>
      <mat-form-field
        appearance="outline"
        class="input-hint border radius-6 big-font full-width margin-b-xl"
      >
        <input
          matInput
          type="text"
          maxlength="60"
          formControlName="name"
          data-cy="organization-name-input"
          [placeholder]="'AddOrganization.Placeholder' | translate"
        />
        <span matSuffix data-cy="organization-name-hint">
          {{ 60 - (form.controls.name.value?.length || 0) }}
          {{ 'Rewrite.Characters left' | translate }}
        </span>

        <mat-error data-cy="organization-name-error">
          @if (form.controls.name.hasError('minlength')) {
            {{ 'Validators.MinimumLength' | translate }}
          }

          @if (form.controls.name.hasError('required')) {
            {{ 'Validators.Required' | translate }}
          }

          @if (form.controls.name.hasError('onlyWhitespacePresent')) {
            {{ 'Validators.WhiteSpace' | translate }}
          }
        </mat-error>
      </mat-form-field>

      <mat-slide-toggle
        formControlName="isInternal"
        [disableRipple]="true"
        data-cy="is-internal-toggle"
        >{{ 'AddOrganization.Toggle' | translate }}</mat-slide-toggle
      >
    </form>
  </ng-container>

  <ng-container actions>
    <app-button
      type="secondary"
      class="margin-r-s"
      text="InputDialog.Cancel"
      [dataCy]="'cancel-dialog'"
      (click)="cancel()"
    />
    <app-button
      type="primary"
      text="AddOrganization.Confirm"
      [dataCy]="'confirm-dialog'"
      [disabled]="form.invalid"
      (click)="confirm()"
    />
  </ng-container>
</app-dialog-wrapper>
