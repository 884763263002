import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import SentryRRWeb from '@sentry/rrweb';
import { environment } from '../environments/environment';

export function initializeSentry(): () => void {
  return () => {
    // Prevent to run on localhost
    if (window.location.hostname === 'localhost') {
      return;
    }

    const config: Sentry.BrowserOptions = {
      dsn: `https://f36304e78ec74a0f92731755ee475fd1@sentry.rtrsupport.de/79`,
      environment: environment.production ? 'master' : 'development',
      release: environment.version,

      sampleRate: 0.5, // only drops events when volume is high
      integrations: [
        new SentryRRWeb({
          // ...options
        }),
      ],
    };

    Sentry.init(config);
  };
}

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandlerService {
  handleError(error: Error): void {
    Sentry.captureException(error);
    throw error;
  }
}
