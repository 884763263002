import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { User, UserRole, UserRoleName } from 'rio-core';
import { ButtonComponent, DialogWrapperComponent } from 'rio-ui';

@Component({
  selector: 'app-edit-user-dialog',
  standalone: true,
  templateUrl: './edit-user-dialog.component.html',
  styleUrl: './edit-user-dialog.component.scss',
  imports: [
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    DialogWrapperComponent,
    ButtonComponent,
    ReactiveFormsModule,
  ],
})
export class EditUserDialogComponent {
  dialogRef = inject(MatDialogRef<EditUserDialogComponent>);
  data: { details: User; role: UserRole } = inject(MAT_DIALOG_DATA);

  UserRoleName = UserRoleName;

  cancel() {
    this.dialogRef.close();
  }
}
