import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LogoComponent } from 'rio-ui';

@Component({
  selector: 'app-no-organization',
  standalone: true,
  imports: [TranslateModule, LogoComponent],
  templateUrl: './no-organization.component.html',
  styleUrl: './no-organization.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoOrganizationComponent {}
