import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

// This code is only for Cypress tests to expose that translations are loaded.
export function cypressTranslationLoaded() {
  const translateService = inject(TranslateService);

  if (window['Cypress']) {
    translateService
      .getTranslation('de')
      .pipe(take(1))
      .subscribe(() => {
        window['translationLoaded'] = true;
      });
  }
}
