import { record } from 'rrweb';
import { addGlobalEventProcessor, getCurrentHub } from '@sentry/browser';
var SentryRRWeb = /** @class */function () {
  function SentryRRWeb(_a) {
    var _this = this;
    var _b = _a === void 0 ? {} : _a,
      checkoutEveryNms = _b.checkoutEveryNms,
      checkoutEveryNth = _b.checkoutEveryNth,
      _c = _b.maskAllInputs,
      maskAllInputs = _c === void 0 ? true : _c;
    this.name = SentryRRWeb.id;
    this.events = [];
    // default checkout time of 5 minutes
    this.checkoutEveryNms = checkoutEveryNms || 5 * 60 * 1000;
    this.checkoutEveryNth = checkoutEveryNth;
    this.maskAllInputs = maskAllInputs;
    this.events = [];
    record({
      checkoutEveryNms: this.checkoutEveryNms,
      checkoutEveryNth: this.checkoutEveryNth,
      maskAllInputs: this.maskAllInputs,
      emit: function (event, isCheckout) {
        if (isCheckout) {
          _this.events = [event];
        } else {
          _this.events.push(event);
        }
      }
    });
  }
  SentryRRWeb.prototype.attachmentUrlFromDsn = function (dsn, eventId) {
    var host = dsn.host,
      path = dsn.path,
      projectId = dsn.projectId,
      port = dsn.port,
      protocol = dsn.protocol,
      user = dsn.user;
    return protocol + "://" + host + (port !== '' ? ":" + port : '') + (path !== '' ? "/" + path : '') + "/api/" + projectId + "/events/" + eventId + "/attachments/?sentry_key=" + user + "&sentry_version=7&sentry_client=rrweb";
  };
  SentryRRWeb.prototype.setupOnce = function () {
    var _this = this;
    addGlobalEventProcessor(function (event) {
      var self = getCurrentHub().getIntegration(SentryRRWeb);
      if (!self) return;
      try {
        // short circuit if theres no events to replay
        if (!_this.events.length) return;
        var client = getCurrentHub().getClient();
        var endpoint = self.attachmentUrlFromDsn(client.getDsn(), event.event_id);
        var formData = new FormData();
        formData.append('rrweb', new Blob([JSON.stringify({
          events: self.events
        })], {
          type: 'application/json'
        }), 'rrweb.json');
        fetch(endpoint, {
          method: 'POST',
          body: formData
        }).catch(function (ex) {
          // we have to catch this otherwise it throws an infinite loop in Sentry
          console.error(ex);
        });
        return event;
      } catch (ex) {
        console.error(ex);
      }
    });
  };
  SentryRRWeb.id = 'SentryRRWeb';
  return SentryRRWeb;
}();
export default SentryRRWeb;