import { Route } from '@angular/router';
import { hasAccessToNoOrganizationGuard, promptEngineerRoleGuard } from 'rio-core';
import { NoOrganizationComponent } from '../components/no-organization/no-organization.component';

export const appRoutes: Route[] = [
  {
    path: '',
    loadChildren: () =>
      import('rio-rewrite-feature').then(m => m.RioRewriteFeatureModule),
  },
  {
    path: 'admin-panel',
    loadChildren: () =>
      import('rio-admin-feature').then(m => m.RioAdminFeatureModule),
    canActivate: [promptEngineerRoleGuard],
  },
  {
    path: 'organization-profile',
    loadChildren: () =>
      import('rio-organization-profile-feature').then(
        m => m.RioOrganizationProfileFeatureModule,
      ),
  },
  {
    path: 'no-organization',
    component: NoOrganizationComponent,
    canActivate: [hasAccessToNoOrganizationGuard],
  },
  { path: '**', redirectTo: '' },
];
