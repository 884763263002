<app-dialog-wrapper>
  <ng-container title>
    {{ 'EditUserDialog.Title' | translate }}
  </ng-container>

  <ng-container content>
    <div class="fxcolumn">
      <span class="form-small-label">{{
        'EditUserDialog.First name' | translate
      }}</span>
      <span class="form-label margin-b-m">{{
        data.details.firstName ? data.details.firstName : '-'
      }}</span>
      <span class="form-small-label">{{
        'EditUserDialog.Last name' | translate
      }}</span>
      <span class="form-label margin-b-m">{{
        data.details.lastName ? data.details.lastName : '-'
      }}</span>
      <span class="form-small-label">{{
        'EditUserDialog.e-mail' | translate
      }}</span>
      <span class="form-label margin-b-m">{{
        data.details.email ? data.details.email : '-'
      }}</span>
      <span class="form-small-label">{{
        'EditUserDialog.Role' | translate
      }}</span
      ><span class="form-label margin-b-m"> {{ UserRoleName[data.role] }}</span>
    </div>
  </ng-container>

  <ng-container actions>
    <app-button type="secondary" text="Rewrite.Cancel" (click)="cancel()" />
  </ng-container>
</app-dialog-wrapper>
