import { WorkerClass } from './_rollup-plugin-web-worker-loader__helper__node__WorkerClass.js';
function decodeBase64(base64, enableUnicode) {
  return Buffer.from(base64, 'base64').toString(enableUnicode ? 'utf16' : 'utf8');
}
function createBase64WorkerFactory(base64, sourcemapArg, enableUnicodeArg) {
  var sourcemap = sourcemapArg === undefined ? null : sourcemapArg;
  var enableUnicode = enableUnicodeArg === undefined ? false : enableUnicodeArg;
  var source = decodeBase64(base64, enableUnicode);
  var start = source.indexOf('\n', 10) + 1;
  var body = source.substring(start) + (sourcemap ? '\/\/# sourceMappingURL=' + sourcemap : '');
  return function WorkerFactory(options) {
    return new WorkerClass(body, Object.assign({}, options, {
      eval: true
    }));
  };
}
export { createBase64WorkerFactory };