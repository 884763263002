<div class="wrapper" data-cy="select-organization-dropdown">
  @if (organizations?.length === 1 && !allowAddingOrganization) {
    <div class="fxflex fxcenterbetween organization-name full-width">
      <span class="black-text">{{ organizations[0]?.name }}</span>
    </div>
  } @else {
    <mat-form-field
      #formField
      appearance="outline"
      class="no-border custom-arrow small-dropdown full-width"
      subscriptSizing="dynamic"
    >
      <mat-select
        #select
        [value]="selectedOrganization"
        (selectionChange)="onOrganizationSelected($event.value)"
        (closed)="clearFilter()"
        (opened)="focusFilter()"
        [placeholder]="select.value?.name"
        [matTooltip]="select.value?.name"
        panelClass="outline-dropdown-panel top-border scrollable filterable fxcolumn"
      >
        <mat-form-field
          class="select-filter white-background no-border"
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <input
            #filterInput
            matInput
            data-cy="organization-filter-input"
            [placeholder]="'Layout.Search organization' | translate"
            [(ngModel)]="filterValue"
          />
        </mat-form-field>
        <div
          class="select-items-scroll-container blue-scrollbar scrollable-content fxgrow"
        >
          @for (
            organization of organizations | filterOrganizations: filterValue;
            track organization.id
          ) {
            <mat-option
              data-cy="select-organization-option"
              [value]="organization"
              [matTooltip]="organization.name"
              [matTooltipDisabled]="wrapper.offsetWidth >= text.scrollWidth"
            >
              <div
                #wrapper
                class="fxflex fxcenterbetween option-wrapper"
                [attr.data-cy]="'option-' + organization.name"
              >
                <span #text class="black-text trimmed">
                  {{ organization.name }}
                </span>
              </div>
            </mat-option>
          }
        </div>
        @if (allowAddingOrganization) {
          <button
            mat-button
            data-cy="add-organization-button"
            class="add-organization"
            (click)="onAddOrganization()"
          >
            <mat-icon>add</mat-icon>
            <span>{{ 'Layout.AddOrganization' | translate }}</span>
          </button>
        }
      </mat-select>
      <mat-icon
        class="dropdown-icon"
        matSuffix
        color="primary"
        svgIcon="chevron-up"
      />
    </mat-form-field>
  }
</div>
