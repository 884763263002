import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { RuntimeChecks, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';

const storeRuntimeChecks: RuntimeChecks = {
  strictStateImmutability: true,
  strictActionImmutability: true,
  strictStateSerializability: true,
  strictActionSerializability: true,
  strictActionTypeUniqueness: true,
  strictActionWithinNgZone: true,
};

@NgModule({
  imports: [
    StoreModule.forRoot({}, { runtimeChecks: storeRuntimeChecks }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
})
export class TioRewriteStoreModule {}
