import { Pipe, PipeTransform } from '@angular/core';
import { Organization } from 'rio-core';

@Pipe({ name: 'filterOrganizations', standalone: true })
export class FilterOrganizationsPipe implements PipeTransform {
  transform(value: Organization[], filter: string): Organization[] {
    return value?.filter(org =>
      org.name.toLocaleLowerCase().includes(filter?.toLocaleLowerCase()),
    );
  }
}
