import { isShadowRoot, isNativeShadowDom, maskInputValue, transformAttribute, needMaskingText, IGNORED_NODE, serializeNodeWithId } from '../../../rrweb-snapshot/es/rrweb-snapshot.js';
import { isIgnored, isBlocked, isSerialized, isAncestorRemoved, isSerializedIframe, isSerializedStylesheet, hasShadowRoot } from '../utils.js';
function isNodeInLinkedList(n) {
  return '__ln' in n;
}
class DoubleLinkedList {
  constructor() {
    this.length = 0;
    this.head = null;
  }
  get(position) {
    if (position >= this.length) {
      throw new Error('Position outside of list range');
    }
    let current = this.head;
    for (let index = 0; index < position; index++) {
      current = (current === null || current === void 0 ? void 0 : current.next) || null;
    }
    return current;
  }
  addNode(n) {
    const node = {
      value: n,
      previous: null,
      next: null
    };
    n.__ln = node;
    if (n.previousSibling && isNodeInLinkedList(n.previousSibling)) {
      const current = n.previousSibling.__ln.next;
      node.next = current;
      node.previous = n.previousSibling.__ln;
      n.previousSibling.__ln.next = node;
      if (current) {
        current.previous = node;
      }
    } else if (n.nextSibling && isNodeInLinkedList(n.nextSibling) && n.nextSibling.__ln.previous) {
      const current = n.nextSibling.__ln.previous;
      node.previous = current;
      node.next = n.nextSibling.__ln;
      n.nextSibling.__ln.previous = node;
      if (current) {
        current.next = node;
      }
    } else {
      if (this.head) {
        this.head.previous = node;
      }
      node.next = this.head;
      this.head = node;
    }
    this.length++;
  }
  removeNode(n) {
    const current = n.__ln;
    if (!this.head) {
      return;
    }
    if (!current.previous) {
      this.head = current.next;
      if (this.head) {
        this.head.previous = null;
      }
    } else {
      current.previous.next = current.next;
      if (current.next) {
        current.next.previous = current.previous;
      }
    }
    if (n.__ln) {
      delete n.__ln;
    }
    this.length--;
  }
}
const moveKey = (id, parentId) => `${id}@${parentId}`;
class MutationBuffer {
  constructor() {
    this.frozen = false;
    this.locked = false;
    this.texts = [];
    this.attributes = [];
    this.removes = [];
    this.mapRemoves = [];
    this.movedMap = {};
    this.addedSet = new Set();
    this.movedSet = new Set();
    this.droppedSet = new Set();
    this.processMutations = mutations => {
      mutations.forEach(this.processMutation);
      this.emit();
    };
    this.emit = () => {
      if (this.frozen || this.locked) {
        return;
      }
      const adds = [];
      const addList = new DoubleLinkedList();
      const getNextId = n => {
        let ns = n;
        let nextId = IGNORED_NODE;
        while (nextId === IGNORED_NODE) {
          ns = ns && ns.nextSibling;
          nextId = ns && this.mirror.getId(ns);
        }
        return nextId;
      };
      const pushAdd = n => {
        var _a, _b, _c, _d;
        let shadowHost = null;
        if (((_b = (_a = n.getRootNode) === null || _a === void 0 ? void 0 : _a.call(n)) === null || _b === void 0 ? void 0 : _b.nodeType) === Node.DOCUMENT_FRAGMENT_NODE && n.getRootNode().host) shadowHost = n.getRootNode().host;
        let rootShadowHost = shadowHost;
        while (((_d = (_c = rootShadowHost === null || rootShadowHost === void 0 ? void 0 : rootShadowHost.getRootNode) === null || _c === void 0 ? void 0 : _c.call(rootShadowHost)) === null || _d === void 0 ? void 0 : _d.nodeType) === Node.DOCUMENT_FRAGMENT_NODE && rootShadowHost.getRootNode().host) rootShadowHost = rootShadowHost.getRootNode().host;
        const notInDoc = !this.doc.contains(n) && (!rootShadowHost || !this.doc.contains(rootShadowHost));
        if (!n.parentNode || notInDoc) {
          return;
        }
        const parentId = isShadowRoot(n.parentNode) ? this.mirror.getId(shadowHost) : this.mirror.getId(n.parentNode);
        const nextId = getNextId(n);
        if (parentId === -1 || nextId === -1) {
          return addList.addNode(n);
        }
        const sn = serializeNodeWithId(n, {
          doc: this.doc,
          mirror: this.mirror,
          blockClass: this.blockClass,
          blockSelector: this.blockSelector,
          maskTextClass: this.maskTextClass,
          maskTextSelector: this.maskTextSelector,
          skipChild: true,
          newlyAddedElement: true,
          inlineStylesheet: this.inlineStylesheet,
          maskInputOptions: this.maskInputOptions,
          maskTextFn: this.maskTextFn,
          maskInputFn: this.maskInputFn,
          slimDOMOptions: this.slimDOMOptions,
          dataURLOptions: this.dataURLOptions,
          recordCanvas: this.recordCanvas,
          inlineImages: this.inlineImages,
          onSerialize: currentN => {
            if (isSerializedIframe(currentN, this.mirror)) {
              this.iframeManager.addIframe(currentN);
            }
            if (isSerializedStylesheet(currentN, this.mirror)) {
              this.stylesheetManager.trackLinkElement(currentN);
            }
            if (hasShadowRoot(n)) {
              this.shadowDomManager.addShadowRoot(n.shadowRoot, this.doc);
            }
          },
          onIframeLoad: (iframe, childSn) => {
            this.iframeManager.attachIframe(iframe, childSn);
            this.shadowDomManager.observeAttachShadow(iframe);
          },
          onStylesheetLoad: (link, childSn) => {
            this.stylesheetManager.attachLinkElement(link, childSn);
          }
        });
        if (sn) {
          adds.push({
            parentId,
            nextId,
            node: sn
          });
        }
      };
      while (this.mapRemoves.length) {
        this.mirror.removeNodeFromMap(this.mapRemoves.shift());
      }
      for (const n of Array.from(this.movedSet.values())) {
        if (isParentRemoved(this.removes, n, this.mirror) && !this.movedSet.has(n.parentNode)) {
          continue;
        }
        pushAdd(n);
      }
      for (const n of Array.from(this.addedSet.values())) {
        if (!isAncestorInSet(this.droppedSet, n) && !isParentRemoved(this.removes, n, this.mirror)) {
          pushAdd(n);
        } else if (isAncestorInSet(this.movedSet, n)) {
          pushAdd(n);
        } else {
          this.droppedSet.add(n);
        }
      }
      let candidate = null;
      while (addList.length) {
        let node = null;
        if (candidate) {
          const parentId = this.mirror.getId(candidate.value.parentNode);
          const nextId = getNextId(candidate.value);
          if (parentId !== -1 && nextId !== -1) {
            node = candidate;
          }
        }
        if (!node) {
          for (let index = addList.length - 1; index >= 0; index--) {
            const _node = addList.get(index);
            if (_node) {
              const parentId = this.mirror.getId(_node.value.parentNode);
              const nextId = getNextId(_node.value);
              if (nextId === -1) continue;else if (parentId !== -1) {
                node = _node;
                break;
              } else {
                const unhandledNode = _node.value;
                if (unhandledNode.parentNode && unhandledNode.parentNode.nodeType === Node.DOCUMENT_FRAGMENT_NODE) {
                  const shadowHost = unhandledNode.parentNode.host;
                  const parentId = this.mirror.getId(shadowHost);
                  if (parentId !== -1) {
                    node = _node;
                    break;
                  }
                }
              }
            }
          }
        }
        if (!node) {
          while (addList.head) {
            addList.removeNode(addList.head.value);
          }
          break;
        }
        candidate = node.previous;
        addList.removeNode(node.value);
        pushAdd(node.value);
      }
      const payload = {
        texts: this.texts.map(text => ({
          id: this.mirror.getId(text.node),
          value: text.value
        })).filter(text => this.mirror.has(text.id)),
        attributes: this.attributes.map(attribute => ({
          id: this.mirror.getId(attribute.node),
          attributes: attribute.attributes
        })).filter(attribute => this.mirror.has(attribute.id)),
        removes: this.removes,
        adds
      };
      if (!payload.texts.length && !payload.attributes.length && !payload.removes.length && !payload.adds.length) {
        return;
      }
      this.texts = [];
      this.attributes = [];
      this.removes = [];
      this.addedSet = new Set();
      this.movedSet = new Set();
      this.droppedSet = new Set();
      this.movedMap = {};
      this.mutationCb(payload);
    };
    this.processMutation = m => {
      if (isIgnored(m.target, this.mirror)) {
        return;
      }
      switch (m.type) {
        case 'characterData':
          {
            const value = m.target.textContent;
            if (!isBlocked(m.target, this.blockClass, this.blockSelector, false) && value !== m.oldValue) {
              this.texts.push({
                value: needMaskingText(m.target, this.maskTextClass, this.maskTextSelector) && value ? this.maskTextFn ? this.maskTextFn(value) : value.replace(/[\S]/g, '*') : value,
                node: m.target
              });
            }
            break;
          }
        case 'attributes':
          {
            const target = m.target;
            let value = m.target.getAttribute(m.attributeName);
            if (m.attributeName === 'value') {
              value = maskInputValue({
                maskInputOptions: this.maskInputOptions,
                tagName: m.target.tagName,
                type: m.target.getAttribute('type'),
                value,
                maskInputFn: this.maskInputFn
              });
            }
            if (isBlocked(m.target, this.blockClass, this.blockSelector, false) || value === m.oldValue) {
              return;
            }
            let item = this.attributes.find(a => a.node === m.target);
            if (target.tagName === 'IFRAME' && m.attributeName === 'src' && !this.keepIframeSrcFn(value)) {
              if (!target.contentDocument) {
                m.attributeName = 'rr_src';
              } else {
                return;
              }
            }
            if (!item) {
              item = {
                node: m.target,
                attributes: {}
              };
              this.attributes.push(item);
            }
            if (m.attributeName === 'style') {
              const old = this.doc.createElement('span');
              if (m.oldValue) {
                old.setAttribute('style', m.oldValue);
              }
              if (item.attributes.style === undefined || item.attributes.style === null) {
                item.attributes.style = {};
              }
              const styleObj = item.attributes.style;
              for (const pname of Array.from(target.style)) {
                const newValue = target.style.getPropertyValue(pname);
                const newPriority = target.style.getPropertyPriority(pname);
                if (newValue !== old.style.getPropertyValue(pname) || newPriority !== old.style.getPropertyPriority(pname)) {
                  if (newPriority === '') {
                    styleObj[pname] = newValue;
                  } else {
                    styleObj[pname] = [newValue, newPriority];
                  }
                }
              }
              for (const pname of Array.from(old.style)) {
                if (target.style.getPropertyValue(pname) === '') {
                  styleObj[pname] = false;
                }
              }
            } else {
              item.attributes[m.attributeName] = transformAttribute(this.doc, target.tagName, m.attributeName, value);
            }
            break;
          }
        case 'childList':
          {
            if (isBlocked(m.target, this.blockClass, this.blockSelector, true)) return;
            m.addedNodes.forEach(n => this.genAdds(n, m.target));
            m.removedNodes.forEach(n => {
              const nodeId = this.mirror.getId(n);
              const parentId = isShadowRoot(m.target) ? this.mirror.getId(m.target.host) : this.mirror.getId(m.target);
              if (isBlocked(m.target, this.blockClass, this.blockSelector, false) || isIgnored(n, this.mirror) || !isSerialized(n, this.mirror)) {
                return;
              }
              if (this.addedSet.has(n)) {
                deepDelete(this.addedSet, n);
                this.droppedSet.add(n);
              } else if (this.addedSet.has(m.target) && nodeId === -1) ;else if (isAncestorRemoved(m.target, this.mirror)) ;else if (this.movedSet.has(n) && this.movedMap[moveKey(nodeId, parentId)]) {
                deepDelete(this.movedSet, n);
              } else {
                this.removes.push({
                  parentId,
                  id: nodeId,
                  isShadow: isShadowRoot(m.target) && isNativeShadowDom(m.target) ? true : undefined
                });
              }
              this.mapRemoves.push(n);
            });
            break;
          }
      }
    };
    this.genAdds = (n, target) => {
      if (this.mirror.hasNode(n)) {
        if (isIgnored(n, this.mirror)) {
          return;
        }
        this.movedSet.add(n);
        let targetId = null;
        if (target && this.mirror.hasNode(target)) {
          targetId = this.mirror.getId(target);
        }
        if (targetId && targetId !== -1) {
          this.movedMap[moveKey(this.mirror.getId(n), targetId)] = true;
        }
      } else {
        this.addedSet.add(n);
        this.droppedSet.delete(n);
      }
      if (!isBlocked(n, this.blockClass, this.blockSelector, false)) n.childNodes.forEach(childN => this.genAdds(childN));
    };
  }
  init(options) {
    ['mutationCb', 'blockClass', 'blockSelector', 'maskTextClass', 'maskTextSelector', 'inlineStylesheet', 'maskInputOptions', 'maskTextFn', 'maskInputFn', 'keepIframeSrcFn', 'recordCanvas', 'inlineImages', 'slimDOMOptions', 'dataURLOptions', 'doc', 'mirror', 'iframeManager', 'stylesheetManager', 'shadowDomManager', 'canvasManager'].forEach(key => {
      this[key] = options[key];
    });
  }
  freeze() {
    this.frozen = true;
    this.canvasManager.freeze();
  }
  unfreeze() {
    this.frozen = false;
    this.canvasManager.unfreeze();
    this.emit();
  }
  isFrozen() {
    return this.frozen;
  }
  lock() {
    this.locked = true;
    this.canvasManager.lock();
  }
  unlock() {
    this.locked = false;
    this.canvasManager.unlock();
    this.emit();
  }
  reset() {
    this.shadowDomManager.reset();
    this.canvasManager.reset();
  }
}
function deepDelete(addsSet, n) {
  addsSet.delete(n);
  n.childNodes.forEach(childN => deepDelete(addsSet, childN));
}
function isParentRemoved(removes, n, mirror) {
  if (removes.length === 0) return false;
  return _isParentRemoved(removes, n, mirror);
}
function _isParentRemoved(removes, n, mirror) {
  const {
    parentNode
  } = n;
  if (!parentNode) {
    return false;
  }
  const parentId = mirror.getId(parentNode);
  if (removes.some(r => r.id === parentId)) {
    return true;
  }
  return _isParentRemoved(removes, parentNode, mirror);
}
function isAncestorInSet(set, n) {
  if (set.size === 0) return false;
  return _isAncestorInSet(set, n);
}
function _isAncestorInSet(set, n) {
  const {
    parentNode
  } = n;
  if (!parentNode) {
    return false;
  }
  if (set.has(parentNode)) {
    return true;
  }
  return _isAncestorInSet(set, parentNode);
}
export { MutationBuffer as default };