<div class="header-container">
  <div class="fxrow fxspacecenter">
    <div class="left-container">
      <img
        alt="logo"
        class="logo-icon clickable"
        src="/assets/svg/retresco.svg"
        (click)="logoClicked($event)"
      />
      <app-select-organization
        [organizations]="userOrganizations$ | async"
        [selectedOrganizationId]="selectedOrganizationId$ | async"
        [allowAddingOrganization]="isSuperAdminOrSupport$ | async"
        (organizationSelected)="onOrganizationSelected($event)"
        (addOrganization)="onAddOrganization()"
      />
    </div>

    <div class="right-container fxrow fxspacecenter">
      <app-icon-button
        icon="profile"
        class="header-item"
        [outline]="false"
        (click)="editProfile()"
      />
      <div class="language-picker header-item" (click)="changeLanguage()">
        {{ unselectedLanguage$ | async | uppercase }}
      </div>
      <div
        class="logout header-item"
        data-cy="logout-button"
        (click)="logoutUser()"
      >
        {{ 'Layout.Logout' | translate }}
      </div>
    </div>
  </div>
  <div>
    <app-header-navigation
      [routes]="userRoutes$ | async"
      (navigate)="onNavigate($event)"
    />
  </div>
</div>
