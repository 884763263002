import { CanvasContext } from '../../../../../types/dist/types.js';
import { patch, isBlocked, hookSetter } from '../../../utils.js';
import { saveWebGLVar, serializeArgs } from './serialize-args.js';
function patchGLPrototype(prototype, type, cb, blockClass, blockSelector, mirror, win) {
  const handlers = [];
  const props = Object.getOwnPropertyNames(prototype);
  for (const prop of props) {
    if (['isContextLost', 'canvas', 'drawingBufferWidth', 'drawingBufferHeight'].includes(prop)) {
      continue;
    }
    try {
      if (typeof prototype[prop] !== 'function') {
        continue;
      }
      const restoreHandler = patch(prototype, prop, function (original) {
        return function (...args) {
          const result = original.apply(this, args);
          saveWebGLVar(result, win, this);
          if (!isBlocked(this.canvas, blockClass, blockSelector, true)) {
            const recordArgs = serializeArgs([...args], win, this);
            const mutation = {
              type,
              property: prop,
              args: recordArgs
            };
            cb(this.canvas, mutation);
          }
          return result;
        };
      });
      handlers.push(restoreHandler);
    } catch (_a) {
      const hookHandler = hookSetter(prototype, prop, {
        set(v) {
          cb(this.canvas, {
            type,
            property: prop,
            args: [v],
            setter: true
          });
        }
      });
      handlers.push(hookHandler);
    }
  }
  return handlers;
}
function initCanvasWebGLMutationObserver(cb, win, blockClass, blockSelector, mirror) {
  const handlers = [];
  handlers.push(...patchGLPrototype(win.WebGLRenderingContext.prototype, CanvasContext.WebGL, cb, blockClass, blockSelector, mirror, win));
  if (typeof win.WebGL2RenderingContext !== 'undefined') {
    handlers.push(...patchGLPrototype(win.WebGL2RenderingContext.prototype, CanvasContext.WebGL2, cb, blockClass, blockSelector, mirror, win));
  }
  return () => {
    handlers.forEach(h => h());
  };
}
export { initCanvasWebGLMutationObserver as default };