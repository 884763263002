import { initMutationObserver, initScrollObserver, initAdoptedStyleSheetObserver } from './observer.js';
import { patch } from '../utils.js';
import { isNativeShadowDom } from '../../../rrweb-snapshot/es/rrweb-snapshot.js';
class ShadowDomManager {
  constructor(options) {
    this.shadowDoms = new WeakSet();
    this.restorePatches = [];
    this.mutationCb = options.mutationCb;
    this.scrollCb = options.scrollCb;
    this.bypassOptions = options.bypassOptions;
    this.mirror = options.mirror;
    const manager = this;
    this.restorePatches.push(patch(Element.prototype, 'attachShadow', function (original) {
      return function (option) {
        const shadowRoot = original.call(this, option);
        if (this.shadowRoot) manager.addShadowRoot(this.shadowRoot, this.ownerDocument);
        return shadowRoot;
      };
    }));
  }
  addShadowRoot(shadowRoot, doc) {
    if (!isNativeShadowDom(shadowRoot)) return;
    if (this.shadowDoms.has(shadowRoot)) return;
    this.shadowDoms.add(shadowRoot);
    initMutationObserver(Object.assign(Object.assign({}, this.bypassOptions), {
      doc,
      mutationCb: this.mutationCb,
      mirror: this.mirror,
      shadowDomManager: this
    }), shadowRoot);
    initScrollObserver(Object.assign(Object.assign({}, this.bypassOptions), {
      scrollCb: this.scrollCb,
      doc: shadowRoot,
      mirror: this.mirror
    }));
    setTimeout(() => {
      if (shadowRoot.adoptedStyleSheets && shadowRoot.adoptedStyleSheets.length > 0) this.bypassOptions.stylesheetManager.adoptStyleSheets(shadowRoot.adoptedStyleSheets, this.mirror.getId(shadowRoot.host));
      initAdoptedStyleSheetObserver({
        mirror: this.mirror,
        stylesheetManager: this.bypassOptions.stylesheetManager
      }, shadowRoot);
    }, 0);
  }
  observeAttachShadow(iframeElement) {
    if (iframeElement.contentWindow) {
      const manager = this;
      this.restorePatches.push(patch(iframeElement.contentWindow.HTMLElement.prototype, 'attachShadow', function (original) {
        return function (option) {
          const shadowRoot = original.call(this, option);
          if (this.shadowRoot) manager.addShadowRoot(this.shadowRoot, iframeElement.contentDocument);
          return shadowRoot;
        };
      }));
    }
  }
  reset() {
    this.restorePatches.forEach(restorePatch => restorePatch());
    this.shadowDoms = new WeakSet();
  }
}
export { ShadowDomManager };