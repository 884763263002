import { maskInputValue } from '../../../rrweb-snapshot/es/rrweb-snapshot.js';
import { on, throttle, isBlocked, getWindowHeight, getWindowWidth, hookSetter, patch, isTouchEvent } from '../utils.js';
import { MouseInteractions, IncrementalSource } from '../../../types/dist/types.js';
import MutationBuffer from './mutation.js';
const mutationBuffers = [];
const isCSSGroupingRuleSupported = typeof CSSGroupingRule !== 'undefined';
const isCSSMediaRuleSupported = typeof CSSMediaRule !== 'undefined';
const isCSSSupportsRuleSupported = typeof CSSSupportsRule !== 'undefined';
const isCSSConditionRuleSupported = typeof CSSConditionRule !== 'undefined';
function getEventTarget(event) {
  try {
    if ('composedPath' in event) {
      const path = event.composedPath();
      if (path.length) {
        return path[0];
      }
    } else if ('path' in event && event.path.length) {
      return event.path[0];
    }
    return event.target;
  } catch (_a) {
    return event.target;
  }
}
function initMutationObserver(options, rootEl) {
  var _a, _b;
  const mutationBuffer = new MutationBuffer();
  mutationBuffers.push(mutationBuffer);
  mutationBuffer.init(options);
  let mutationObserverCtor = window.MutationObserver || window.__rrMutationObserver;
  const angularZoneSymbol = (_b = (_a = window === null || window === void 0 ? void 0 : window.Zone) === null || _a === void 0 ? void 0 : _a.__symbol__) === null || _b === void 0 ? void 0 : _b.call(_a, 'MutationObserver');
  if (angularZoneSymbol && window[angularZoneSymbol]) {
    mutationObserverCtor = window[angularZoneSymbol];
  }
  const observer = new mutationObserverCtor(mutationBuffer.processMutations.bind(mutationBuffer));
  observer.observe(rootEl, {
    attributes: true,
    attributeOldValue: true,
    characterData: true,
    characterDataOldValue: true,
    childList: true,
    subtree: true
  });
  return observer;
}
function initMoveObserver({
  mousemoveCb,
  sampling,
  doc,
  mirror
}) {
  if (sampling.mousemove === false) {
    return () => {};
  }
  const threshold = typeof sampling.mousemove === 'number' ? sampling.mousemove : 50;
  const callbackThreshold = typeof sampling.mousemoveCallback === 'number' ? sampling.mousemoveCallback : 500;
  let positions = [];
  let timeBaseline;
  const wrappedCb = throttle(source => {
    const totalOffset = Date.now() - timeBaseline;
    mousemoveCb(positions.map(p => {
      p.timeOffset -= totalOffset;
      return p;
    }), source);
    positions = [];
    timeBaseline = null;
  }, callbackThreshold);
  const updatePosition = throttle(evt => {
    const target = getEventTarget(evt);
    const {
      clientX,
      clientY
    } = isTouchEvent(evt) ? evt.changedTouches[0] : evt;
    if (!timeBaseline) {
      timeBaseline = Date.now();
    }
    positions.push({
      x: clientX,
      y: clientY,
      id: mirror.getId(target),
      timeOffset: Date.now() - timeBaseline
    });
    wrappedCb(typeof DragEvent !== 'undefined' && evt instanceof DragEvent ? IncrementalSource.Drag : evt instanceof MouseEvent ? IncrementalSource.MouseMove : IncrementalSource.TouchMove);
  }, threshold, {
    trailing: false
  });
  const handlers = [on('mousemove', updatePosition, doc), on('touchmove', updatePosition, doc), on('drag', updatePosition, doc)];
  return () => {
    handlers.forEach(h => h());
  };
}
function initMouseInteractionObserver({
  mouseInteractionCb,
  doc,
  mirror,
  blockClass,
  blockSelector,
  sampling
}) {
  if (sampling.mouseInteraction === false) {
    return () => {};
  }
  const disableMap = sampling.mouseInteraction === true || sampling.mouseInteraction === undefined ? {} : sampling.mouseInteraction;
  const handlers = [];
  const getHandler = eventKey => {
    return event => {
      const target = getEventTarget(event);
      if (isBlocked(target, blockClass, blockSelector, true)) {
        return;
      }
      const e = isTouchEvent(event) ? event.changedTouches[0] : event;
      if (!e) {
        return;
      }
      const id = mirror.getId(target);
      const {
        clientX,
        clientY
      } = e;
      mouseInteractionCb({
        type: MouseInteractions[eventKey],
        id,
        x: clientX,
        y: clientY
      });
    };
  };
  Object.keys(MouseInteractions).filter(key => Number.isNaN(Number(key)) && !key.endsWith('_Departed') && disableMap[key] !== false).forEach(eventKey => {
    const eventName = eventKey.toLowerCase();
    const handler = getHandler(eventKey);
    handlers.push(on(eventName, handler, doc));
  });
  return () => {
    handlers.forEach(h => h());
  };
}
function initScrollObserver({
  scrollCb,
  doc,
  mirror,
  blockClass,
  blockSelector,
  sampling
}) {
  const updatePosition = throttle(evt => {
    const target = getEventTarget(evt);
    if (!target || isBlocked(target, blockClass, blockSelector, true)) {
      return;
    }
    const id = mirror.getId(target);
    if (target === doc) {
      const scrollEl = doc.scrollingElement || doc.documentElement;
      scrollCb({
        id,
        x: scrollEl.scrollLeft,
        y: scrollEl.scrollTop
      });
    } else {
      scrollCb({
        id,
        x: target.scrollLeft,
        y: target.scrollTop
      });
    }
  }, sampling.scroll || 100);
  return on('scroll', updatePosition, doc);
}
function initViewportResizeObserver({
  viewportResizeCb
}) {
  let lastH = -1;
  let lastW = -1;
  const updateDimension = throttle(() => {
    const height = getWindowHeight();
    const width = getWindowWidth();
    if (lastH !== height || lastW !== width) {
      viewportResizeCb({
        width: Number(width),
        height: Number(height)
      });
      lastH = height;
      lastW = width;
    }
  }, 200);
  return on('resize', updateDimension, window);
}
function wrapEventWithUserTriggeredFlag(v, enable) {
  const value = Object.assign({}, v);
  if (!enable) delete value.userTriggered;
  return value;
}
const INPUT_TAGS = ['INPUT', 'TEXTAREA', 'SELECT'];
const lastInputValueMap = new WeakMap();
function initInputObserver({
  inputCb,
  doc,
  mirror,
  blockClass,
  blockSelector,
  ignoreClass,
  maskInputOptions,
  maskInputFn,
  sampling,
  userTriggeredOnInput
}) {
  function eventHandler(event) {
    let target = getEventTarget(event);
    const userTriggered = event.isTrusted;
    if (target && target.tagName === 'OPTION') target = target.parentElement;
    if (!target || !target.tagName || INPUT_TAGS.indexOf(target.tagName) < 0 || isBlocked(target, blockClass, blockSelector, true)) {
      return;
    }
    const type = target.type;
    if (target.classList.contains(ignoreClass)) {
      return;
    }
    let text = target.value;
    let isChecked = false;
    if (type === 'radio' || type === 'checkbox') {
      isChecked = target.checked;
    } else if (maskInputOptions[target.tagName.toLowerCase()] || maskInputOptions[type]) {
      text = maskInputValue({
        maskInputOptions,
        tagName: target.tagName,
        type,
        value: text,
        maskInputFn
      });
    }
    cbWithDedup(target, wrapEventWithUserTriggeredFlag({
      text,
      isChecked,
      userTriggered
    }, userTriggeredOnInput));
    const name = target.name;
    if (type === 'radio' && name && isChecked) {
      doc.querySelectorAll(`input[type="radio"][name="${name}"]`).forEach(el => {
        if (el !== target) {
          cbWithDedup(el, wrapEventWithUserTriggeredFlag({
            text: el.value,
            isChecked: !isChecked,
            userTriggered: false
          }, userTriggeredOnInput));
        }
      });
    }
  }
  function cbWithDedup(target, v) {
    const lastInputValue = lastInputValueMap.get(target);
    if (!lastInputValue || lastInputValue.text !== v.text || lastInputValue.isChecked !== v.isChecked) {
      lastInputValueMap.set(target, v);
      const id = mirror.getId(target);
      inputCb(Object.assign(Object.assign({}, v), {
        id
      }));
    }
  }
  const events = sampling.input === 'last' ? ['change'] : ['input', 'change'];
  const handlers = events.map(eventName => on(eventName, eventHandler, doc));
  const currentWindow = doc.defaultView;
  if (!currentWindow) {
    return () => {
      handlers.forEach(h => h());
    };
  }
  const propertyDescriptor = currentWindow.Object.getOwnPropertyDescriptor(currentWindow.HTMLInputElement.prototype, 'value');
  const hookProperties = [[currentWindow.HTMLInputElement.prototype, 'value'], [currentWindow.HTMLInputElement.prototype, 'checked'], [currentWindow.HTMLSelectElement.prototype, 'value'], [currentWindow.HTMLTextAreaElement.prototype, 'value'], [currentWindow.HTMLSelectElement.prototype, 'selectedIndex'], [currentWindow.HTMLOptionElement.prototype, 'selected']];
  if (propertyDescriptor && propertyDescriptor.set) {
    handlers.push(...hookProperties.map(p => hookSetter(p[0], p[1], {
      set() {
        eventHandler({
          target: this
        });
      }
    }, false, currentWindow)));
  }
  return () => {
    handlers.forEach(h => h());
  };
}
function getNestedCSSRulePositions(rule) {
  const positions = [];
  function recurse(childRule, pos) {
    if (isCSSGroupingRuleSupported && childRule.parentRule instanceof CSSGroupingRule || isCSSMediaRuleSupported && childRule.parentRule instanceof CSSMediaRule || isCSSSupportsRuleSupported && childRule.parentRule instanceof CSSSupportsRule || isCSSConditionRuleSupported && childRule.parentRule instanceof CSSConditionRule) {
      const rules = Array.from(childRule.parentRule.cssRules);
      const index = rules.indexOf(childRule);
      pos.unshift(index);
    } else if (childRule.parentStyleSheet) {
      const rules = Array.from(childRule.parentStyleSheet.cssRules);
      const index = rules.indexOf(childRule);
      pos.unshift(index);
    }
    return pos;
  }
  return recurse(rule, positions);
}
function getIdAndStyleId(sheet, mirror, styleMirror) {
  let id, styleId;
  if (!sheet) return {};
  if (sheet.ownerNode) id = mirror.getId(sheet.ownerNode);else styleId = styleMirror.getId(sheet);
  return {
    styleId,
    id
  };
}
function initStyleSheetObserver({
  styleSheetRuleCb,
  mirror,
  stylesheetManager
}, {
  win
}) {
  const insertRule = win.CSSStyleSheet.prototype.insertRule;
  win.CSSStyleSheet.prototype.insertRule = function (rule, index) {
    const {
      id,
      styleId
    } = getIdAndStyleId(this, mirror, stylesheetManager.styleMirror);
    if (id && id !== -1 || styleId && styleId !== -1) {
      styleSheetRuleCb({
        id,
        styleId,
        adds: [{
          rule,
          index
        }]
      });
    }
    return insertRule.apply(this, [rule, index]);
  };
  const deleteRule = win.CSSStyleSheet.prototype.deleteRule;
  win.CSSStyleSheet.prototype.deleteRule = function (index) {
    const {
      id,
      styleId
    } = getIdAndStyleId(this, mirror, stylesheetManager.styleMirror);
    if (id && id !== -1 || styleId && styleId !== -1) {
      styleSheetRuleCb({
        id,
        styleId,
        removes: [{
          index
        }]
      });
    }
    return deleteRule.apply(this, [index]);
  };
  let replace;
  if (win.CSSStyleSheet.prototype.replace) {
    replace = win.CSSStyleSheet.prototype.replace;
    win.CSSStyleSheet.prototype.replace = function (text) {
      const {
        id,
        styleId
      } = getIdAndStyleId(this, mirror, stylesheetManager.styleMirror);
      if (id && id !== -1 || styleId && styleId !== -1) {
        styleSheetRuleCb({
          id,
          styleId,
          replace: text
        });
      }
      return replace.apply(this, [text]);
    };
  }
  let replaceSync;
  if (win.CSSStyleSheet.prototype.replaceSync) {
    replaceSync = win.CSSStyleSheet.prototype.replaceSync;
    win.CSSStyleSheet.prototype.replaceSync = function (text) {
      const {
        id,
        styleId
      } = getIdAndStyleId(this, mirror, stylesheetManager.styleMirror);
      if (id && id !== -1 || styleId && styleId !== -1) {
        styleSheetRuleCb({
          id,
          styleId,
          replaceSync: text
        });
      }
      return replaceSync.apply(this, [text]);
    };
  }
  const supportedNestedCSSRuleTypes = {};
  if (isCSSGroupingRuleSupported) {
    supportedNestedCSSRuleTypes.CSSGroupingRule = win.CSSGroupingRule;
  } else {
    if (isCSSMediaRuleSupported) {
      supportedNestedCSSRuleTypes.CSSMediaRule = win.CSSMediaRule;
    }
    if (isCSSConditionRuleSupported) {
      supportedNestedCSSRuleTypes.CSSConditionRule = win.CSSConditionRule;
    }
    if (isCSSSupportsRuleSupported) {
      supportedNestedCSSRuleTypes.CSSSupportsRule = win.CSSSupportsRule;
    }
  }
  const unmodifiedFunctions = {};
  Object.entries(supportedNestedCSSRuleTypes).forEach(([typeKey, type]) => {
    unmodifiedFunctions[typeKey] = {
      insertRule: type.prototype.insertRule,
      deleteRule: type.prototype.deleteRule
    };
    type.prototype.insertRule = function (rule, index) {
      const {
        id,
        styleId
      } = getIdAndStyleId(this.parentStyleSheet, mirror, stylesheetManager.styleMirror);
      if (id && id !== -1 || styleId && styleId !== -1) {
        styleSheetRuleCb({
          id,
          styleId,
          adds: [{
            rule,
            index: [...getNestedCSSRulePositions(this), index || 0]
          }]
        });
      }
      return unmodifiedFunctions[typeKey].insertRule.apply(this, [rule, index]);
    };
    type.prototype.deleteRule = function (index) {
      const {
        id,
        styleId
      } = getIdAndStyleId(this.parentStyleSheet, mirror, stylesheetManager.styleMirror);
      if (id && id !== -1 || styleId && styleId !== -1) {
        styleSheetRuleCb({
          id,
          styleId,
          removes: [{
            index: [...getNestedCSSRulePositions(this), index]
          }]
        });
      }
      return unmodifiedFunctions[typeKey].deleteRule.apply(this, [index]);
    };
  });
  return () => {
    win.CSSStyleSheet.prototype.insertRule = insertRule;
    win.CSSStyleSheet.prototype.deleteRule = deleteRule;
    replace && (win.CSSStyleSheet.prototype.replace = replace);
    replaceSync && (win.CSSStyleSheet.prototype.replaceSync = replaceSync);
    Object.entries(supportedNestedCSSRuleTypes).forEach(([typeKey, type]) => {
      type.prototype.insertRule = unmodifiedFunctions[typeKey].insertRule;
      type.prototype.deleteRule = unmodifiedFunctions[typeKey].deleteRule;
    });
  };
}
function initAdoptedStyleSheetObserver({
  mirror,
  stylesheetManager
}, host) {
  var _a, _b, _c;
  let hostId = null;
  if (host.nodeName === '#document') hostId = mirror.getId(host);else hostId = mirror.getId(host.host);
  const patchTarget = host.nodeName === '#document' ? (_a = host.defaultView) === null || _a === void 0 ? void 0 : _a.Document : (_c = (_b = host.ownerDocument) === null || _b === void 0 ? void 0 : _b.defaultView) === null || _c === void 0 ? void 0 : _c.ShadowRoot;
  const originalPropertyDescriptor = Object.getOwnPropertyDescriptor(patchTarget === null || patchTarget === void 0 ? void 0 : patchTarget.prototype, 'adoptedStyleSheets');
  if (hostId === null || hostId === -1 || !patchTarget || !originalPropertyDescriptor) return () => {};
  Object.defineProperty(host, 'adoptedStyleSheets', {
    configurable: originalPropertyDescriptor.configurable,
    enumerable: originalPropertyDescriptor.enumerable,
    get() {
      var _a;
      return (_a = originalPropertyDescriptor.get) === null || _a === void 0 ? void 0 : _a.call(this);
    },
    set(sheets) {
      var _a;
      const result = (_a = originalPropertyDescriptor.set) === null || _a === void 0 ? void 0 : _a.call(this, sheets);
      if (hostId !== null && hostId !== -1) {
        try {
          stylesheetManager.adoptStyleSheets(sheets, hostId);
        } catch (e) {}
      }
      return result;
    }
  });
  return () => {
    Object.defineProperty(host, 'adoptedStyleSheets', {
      configurable: originalPropertyDescriptor.configurable,
      enumerable: originalPropertyDescriptor.enumerable,
      get: originalPropertyDescriptor.get,
      set: originalPropertyDescriptor.set
    });
  };
}
function initStyleDeclarationObserver({
  styleDeclarationCb,
  mirror,
  ignoreCSSAttributes,
  stylesheetManager
}, {
  win
}) {
  const setProperty = win.CSSStyleDeclaration.prototype.setProperty;
  win.CSSStyleDeclaration.prototype.setProperty = function (property, value, priority) {
    var _a;
    if (ignoreCSSAttributes.has(property)) {
      return setProperty.apply(this, [property, value, priority]);
    }
    const {
      id,
      styleId
    } = getIdAndStyleId((_a = this.parentRule) === null || _a === void 0 ? void 0 : _a.parentStyleSheet, mirror, stylesheetManager.styleMirror);
    if (id && id !== -1 || styleId && styleId !== -1) {
      styleDeclarationCb({
        id,
        styleId,
        set: {
          property,
          value,
          priority
        },
        index: getNestedCSSRulePositions(this.parentRule)
      });
    }
    return setProperty.apply(this, [property, value, priority]);
  };
  const removeProperty = win.CSSStyleDeclaration.prototype.removeProperty;
  win.CSSStyleDeclaration.prototype.removeProperty = function (property) {
    var _a;
    if (ignoreCSSAttributes.has(property)) {
      return removeProperty.apply(this, [property]);
    }
    const {
      id,
      styleId
    } = getIdAndStyleId((_a = this.parentRule) === null || _a === void 0 ? void 0 : _a.parentStyleSheet, mirror, stylesheetManager.styleMirror);
    if (id && id !== -1 || styleId && styleId !== -1) {
      styleDeclarationCb({
        id,
        styleId,
        remove: {
          property
        },
        index: getNestedCSSRulePositions(this.parentRule)
      });
    }
    return removeProperty.apply(this, [property]);
  };
  return () => {
    win.CSSStyleDeclaration.prototype.setProperty = setProperty;
    win.CSSStyleDeclaration.prototype.removeProperty = removeProperty;
  };
}
function initMediaInteractionObserver({
  mediaInteractionCb,
  blockClass,
  blockSelector,
  mirror,
  sampling
}) {
  const handler = type => throttle(event => {
    const target = getEventTarget(event);
    if (!target || isBlocked(target, blockClass, blockSelector, true)) {
      return;
    }
    const {
      currentTime,
      volume,
      muted,
      playbackRate
    } = target;
    mediaInteractionCb({
      type,
      id: mirror.getId(target),
      currentTime,
      volume,
      muted,
      playbackRate
    });
  }, sampling.media || 500);
  const handlers = [on('play', handler(0)), on('pause', handler(1)), on('seeked', handler(2)), on('volumechange', handler(3)), on('ratechange', handler(4))];
  return () => {
    handlers.forEach(h => h());
  };
}
function initFontObserver({
  fontCb,
  doc
}) {
  const win = doc.defaultView;
  if (!win) {
    return () => {};
  }
  const handlers = [];
  const fontMap = new WeakMap();
  const originalFontFace = win.FontFace;
  win.FontFace = function FontFace(family, source, descriptors) {
    const fontFace = new originalFontFace(family, source, descriptors);
    fontMap.set(fontFace, {
      family,
      buffer: typeof source !== 'string',
      descriptors,
      fontSource: typeof source === 'string' ? source : JSON.stringify(Array.from(new Uint8Array(source)))
    });
    return fontFace;
  };
  const restoreHandler = patch(doc.fonts, 'add', function (original) {
    return function (fontFace) {
      setTimeout(() => {
        const p = fontMap.get(fontFace);
        if (p) {
          fontCb(p);
          fontMap.delete(fontFace);
        }
      }, 0);
      return original.apply(this, [fontFace]);
    };
  });
  handlers.push(() => {
    win.FontFace = originalFontFace;
  });
  handlers.push(restoreHandler);
  return () => {
    handlers.forEach(h => h());
  };
}
function initSelectionObserver(param) {
  const {
    doc,
    mirror,
    blockClass,
    blockSelector,
    selectionCb
  } = param;
  let collapsed = true;
  const updateSelection = () => {
    const selection = doc.getSelection();
    if (!selection || collapsed && (selection === null || selection === void 0 ? void 0 : selection.isCollapsed)) return;
    collapsed = selection.isCollapsed || false;
    const ranges = [];
    const count = selection.rangeCount || 0;
    for (let i = 0; i < count; i++) {
      const range = selection.getRangeAt(i);
      const {
        startContainer,
        startOffset,
        endContainer,
        endOffset
      } = range;
      const blocked = isBlocked(startContainer, blockClass, blockSelector, true) || isBlocked(endContainer, blockClass, blockSelector, true);
      if (blocked) continue;
      ranges.push({
        start: mirror.getId(startContainer),
        startOffset,
        end: mirror.getId(endContainer),
        endOffset
      });
    }
    selectionCb({
      ranges
    });
  };
  updateSelection();
  return on('selectionchange', updateSelection);
}
function mergeHooks(o, hooks) {
  const {
    mutationCb,
    mousemoveCb,
    mouseInteractionCb,
    scrollCb,
    viewportResizeCb,
    inputCb,
    mediaInteractionCb,
    styleSheetRuleCb,
    styleDeclarationCb,
    canvasMutationCb,
    fontCb,
    selectionCb
  } = o;
  o.mutationCb = (...p) => {
    if (hooks.mutation) {
      hooks.mutation(...p);
    }
    mutationCb(...p);
  };
  o.mousemoveCb = (...p) => {
    if (hooks.mousemove) {
      hooks.mousemove(...p);
    }
    mousemoveCb(...p);
  };
  o.mouseInteractionCb = (...p) => {
    if (hooks.mouseInteraction) {
      hooks.mouseInteraction(...p);
    }
    mouseInteractionCb(...p);
  };
  o.scrollCb = (...p) => {
    if (hooks.scroll) {
      hooks.scroll(...p);
    }
    scrollCb(...p);
  };
  o.viewportResizeCb = (...p) => {
    if (hooks.viewportResize) {
      hooks.viewportResize(...p);
    }
    viewportResizeCb(...p);
  };
  o.inputCb = (...p) => {
    if (hooks.input) {
      hooks.input(...p);
    }
    inputCb(...p);
  };
  o.mediaInteractionCb = (...p) => {
    if (hooks.mediaInteaction) {
      hooks.mediaInteaction(...p);
    }
    mediaInteractionCb(...p);
  };
  o.styleSheetRuleCb = (...p) => {
    if (hooks.styleSheetRule) {
      hooks.styleSheetRule(...p);
    }
    styleSheetRuleCb(...p);
  };
  o.styleDeclarationCb = (...p) => {
    if (hooks.styleDeclaration) {
      hooks.styleDeclaration(...p);
    }
    styleDeclarationCb(...p);
  };
  o.canvasMutationCb = (...p) => {
    if (hooks.canvasMutation) {
      hooks.canvasMutation(...p);
    }
    canvasMutationCb(...p);
  };
  o.fontCb = (...p) => {
    if (hooks.font) {
      hooks.font(...p);
    }
    fontCb(...p);
  };
  o.selectionCb = (...p) => {
    if (hooks.selection) {
      hooks.selection(...p);
    }
    selectionCb(...p);
  };
}
function initObservers(o, hooks = {}) {
  const currentWindow = o.doc.defaultView;
  if (!currentWindow) {
    return () => {};
  }
  mergeHooks(o, hooks);
  const mutationObserver = initMutationObserver(o, o.doc);
  const mousemoveHandler = initMoveObserver(o);
  const mouseInteractionHandler = initMouseInteractionObserver(o);
  const scrollHandler = initScrollObserver(o);
  const viewportResizeHandler = initViewportResizeObserver(o);
  const inputHandler = initInputObserver(o);
  const mediaInteractionHandler = initMediaInteractionObserver(o);
  const styleSheetObserver = initStyleSheetObserver(o, {
    win: currentWindow
  });
  const adoptedStyleSheetObserver = initAdoptedStyleSheetObserver(o, o.doc);
  const styleDeclarationObserver = initStyleDeclarationObserver(o, {
    win: currentWindow
  });
  const fontObserver = o.collectFonts ? initFontObserver(o) : () => {};
  const selectionObserver = initSelectionObserver(o);
  const pluginHandlers = [];
  for (const plugin of o.plugins) {
    pluginHandlers.push(plugin.observer(plugin.callback, currentWindow, plugin.options));
  }
  return () => {
    mutationBuffers.forEach(b => b.reset());
    mutationObserver.disconnect();
    mousemoveHandler();
    mouseInteractionHandler();
    scrollHandler();
    viewportResizeHandler();
    inputHandler();
    mediaInteractionHandler();
    styleSheetObserver();
    adoptedStyleSheetObserver();
    styleDeclarationObserver();
    fontObserver();
    selectionObserver();
    pluginHandlers.forEach(h => h());
  };
}
export { INPUT_TAGS, initAdoptedStyleSheetObserver, initMutationObserver, initObservers, initScrollObserver, mutationBuffers };